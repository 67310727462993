<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link>
                    <!-- <router-link :to="{ name: 'account-search-domain', params: { accountId: this.$route.params.accountId } }">Domains</router-link> &gt;
                    <router-link :to="{ name: 'account-view-domain', params: { accountId: this.$route.params.accountId, domain: this.$route.params.domain } }">{{ this.$route.params.domain }}</router-link> -->
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Create new website alias</h1>
                <!-- <p class="text-caption text-center">Websites</p> -->
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card tile elevation="4" class="pa-0">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="purple--text">Create new website alias</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="px-5">
                        <v-form @submit="create" onSubmit="return false;" @keyup.enter.native="create">
                            <SelectWebsite v-model="websiteId"/>
                            <SelectDomain v-model="domain"/>
                            <v-text-field
                                ref="hostnameInput"
                                v-model="newHostname"
                                label="Hostname"
                                :rules="newHostnameRules"
                                validate-on-blur
                                color="purple"
                                required
                                hint="A hostname in the selected domain, for example 'www'; use '@' to refer to the domain itself"
                                type="text"
                                outlined
                            >
                            </v-text-field>
                        </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" class="purple white--text" @click="create" :disabled="!isFormComplete">
                                <span>Create</span>
                            </v-btn>
                            <v-btn text color="grey" @click="cancel">
                                <span>Cancel</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import { isValidName, compact } from '@/sdk/input';
import SelectDomain from '@/components/select-item/SelectDomain.vue';
import SelectWebsite from '@/components/select-item/SelectWebsite.vue';

export default {
    components: {
        SelectDomain,
        SelectWebsite,
    },
    data: () => ({
        error: null,
        account: null,
        websiteId: null,
        domain: null,

        newHostname: null,
        newHostnameRules: [
            (v) => !!v || 'Hostname is required',
            (v) => !v || v === '@' || isValidName(compact(v)) || 'Hostname is required',
        ],
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
        }),
        isFormComplete() {
            // TODO: check that site name is not too similar to existing site name
            return typeof this.websiteId === 'string' && this.websiteId.trim().length > 0 && typeof this.domain === 'string' && this.domain.trim().length > 0 && typeof this.newHostname === 'string' && this.newHostname.trim().length > 0;
        },
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
    },
    // watch: {
    //     '$route.query'
    //     if (this.$route.query.websiteId) {
    //         this.websiteId = this.$route.query.websiteId;
    //     }
    //     if (this.$route.query.domain) {
    //         this.domain = this.$route.query.domain;
    //     }
    // },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        create() {
            if (!this.isFormComplete) {
                return;
            }
            this.createWebsiteAlias();
        },
        cancel() {
            // this.$emit('cancel');
            this.$router.go(-1);
        },
        async createWebsiteAlias() {
            try {
                this.error = false;
                this.$store.commit('loading', { createWebsiteAlias: true });
                console.log('createWebsiteAlias');
                const request = {
                    website_id: this.websiteId,
                    domain: this.domain,
                    hostname: this.newHostname,
                };
                const response = await this.$client.account(this.$route.params.accountId).domain.createWebsiteAlias(request);
                console.log('createWebsiteAlias response: %o', response);
                const { isCreated, id, error } = response;
                if (isCreated) {
                    // const site = { id, ...request };
                    // this.list.push(site); // this SHOULD work (https://vuejs.org/v2/guide/list.html#Mutation-Methods) but doesn't work.
                    // this.$router.replace({ name: 'account-edit-website', params: { accountId: this.$route.params.accountId, websiteId: id }, query: { domain: this.domain } });
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'Created website alias' });
                    this.$router.go(-1);
                } else if (error) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create website alias' });
                    this.error = error;
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create website alias' });
                    this.error = 'Request failed';
                }
                this.$emit('created', { id });
            } catch (err) {
                console.error('failed to create site', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create website alias' });
            } finally {
                this.$store.commit('loading', { createWebsiteAlias: false });
            }
        },
    },

    mounted() {
        this.loadAccount();
        if (this.$route.query.websiteId) {
            this.websiteId = this.$route.query.websiteId;
        }
        if (this.$route.query.domain) {
            this.domain = this.$route.query.domain;
        }
    },
};
</script>
