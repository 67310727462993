<template>
    <v-select outlined v-model="websiteId" label="Select website" :items="list" item-text="label" item-value="id">
            <!-- <v-btn icon color="purple" :to="{ name: 'account-create-websiteId', params: { accountId: this.$route.params.accountId } }">
                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
            </v-btn> -->
    </v-select>
</template>

<script>
export default {
    // TODO: prop should allow to filter websiteIds based on status,m e.g. only show 'verified' websiteIds
    props: {
        value: {
            type: String,
            default: null,
            required: false,
        },
    },

    data: () => ({
        // TODO: eliminate these temporary flags and usage in the template; maybe create a different component for "select a websiteId"
        websiteId: null,
        list: [],
    }),

    watch: {
        websiteId(newValue) {
            this.$emit('input', newValue);
        },
    },

    methods: {
        async loadWebsiteList() {
            try {
                this.$store.commit('loading', { loadWebsiteList: true });
                const response = await this.$client.account(this.$route.params.accountId).domain.searchWebsite();
                if (response?.list) {
                    this.list = response.list;
                }
            } catch (err) {
                console.error('loadWebsiteList failed', err);
            } finally {
                this.$store.commit('loading', { loadWebsiteList: false });
            }
        },
    },

    mounted() {
        this.loadWebsiteList();
    },
};
</script>
